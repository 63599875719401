<template>
  <div class="popup-container is-active">
    <!-- popup -->
    <div class="popup" id="popup-talentboard-o026">
      <!-- popup > popup-inner -->
      <div class="popup-inner">
        <div>
          <!-- popup-inner > popup-content -->
          <div class="popup-content">

            <div class="pop-coaching-container">
              <!-- popup-summary -->
              <div class="popup-summary">
                <div class="learner-avatar">
                  <div class="image">
                    <ProfileImg
                        :target="target"
                    />
                  </div>
                </div>
                <div class="learner-header">
                  <p class="subtitle">코칭관리</p>
                  <h3 class="title">{{ target.lrnerNm }} ・ {{ target.jbgdCd }}</h3>
                </div>
                <div class="learner-meta">
<!--                  <span class="meta">소속 반포</span>-->
                  <span class="meta">{{ target.deptNm }}</span>
                  <span v-if="target.curtJbgdPromoYmd" class="meta">{{ `최근승격년 ${target.curtJbgdPromoYmd.substr(0, 4)}` }}</span>
                  <span class="meta">희망 학습분야 {{ hopeGoal.crseWorkDtlNm }}</span>
                  <span v-if="target.bestAbility.crseWorkDtlNm" class="meta">우수 학습분야 {{ target.bestAbility.crseWorkDtlNm }}</span>
                </div>
              </div>
              <!-- popup-section -->
              <article class="popup-section section-lerner">
                <header class="section-header"><h3 class="title">학습자 정보</h3></header>
                <div class="kb-table kb-table-striped">
                  <table>
                    <colgroup>
                      <col style="width:180px;">
                      <col>
                    </colgroup>
                    <tbody>
                    <tr>
                      <th><span class="th-title">현재 학습분야</span></th>
                      <td><span class="td-text">{{ nowGoal.crseWorkDtlNm }}</span></td>
                    </tr>
                    <tr>
                      <th><span class="th-title">희망 학습분야</span></th>
                      <td><span class="td-text">{{ hopeGoal.crseWorkDtlNm }}</span></td>
                    </tr>
                    <tr>
                      <th><span class="th-title">희망레벨</span></th>
                      <td><span class="td-text">전문가(Expert)</span></td>
                    </tr>
                    <tr>
                      <th><span class="th-title">목표기한</span></th>
                      <td><span class="td-text">{{ hopeGoal.goalsEndYmd ? hopeGoal.goalsEndYmd.replace('-', '.').replace('-', '.') : '-' }}</span></td>
                    </tr>
                    <tr>
                      <th><span class="th-title">취득희망 자격증</span></th>
                      <td><span class="td-text">{{ hopeGoal.licenses && hopeGoal.licenses.length > 0 ? hopeGoal.licenses.map(x => x.lcsnNm).join(' ・ ') : '-'}}</span></td>
                    </tr>
                    <tr>
                      <th><span class="th-title">목표</span></th>
                      <td>
                        <div class="td-cell">
                          <p class="td-text">{{ hopeGoal.goalsCn }}</p>
                          <p v-if="hopeGoal.crseWorkDtlSn > 0" class="td-text text-muted text-date">{{ timestampToDateFormat(hopeGoal.mdfcnDt, 'yyyy.MM.dd hh:mm') }} 등록</p>
                        </div>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </article>

              <template v-if="isReady">
                <!-- 코칭 등록  -->

                <article v-if="session.deptCd === target.deptCd" class="popup-section section-divider section-coaching">
                  <div class="kb-form-fields">
                    <div class="kb-form-row">
                      <div class="kb-form-column kb-form-column-title">
                        <label class="kb-form-label">
                          <span class="kb-form-label-text">코칭</span>
                        </label>
                      </div>
                      <div class="kb-form-column">
                        <textarea v-model.trim="coachingCn" cols="30" rows="6" class="kb-form-control" placeholder="목표를 확인 후 코칭을 등록해주세요."></textarea>
                        <div class="textarea-bottom">
                          <div class="bottom-column">
                            <span class="counter">{{ coachingCnLen }} / 300</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <p v-if="coaching.crseWorkGoalsSn > 0" class="segment-meta">{{ coaching.mdfrNm }}({{ coaching.mdfrId }})  <span class="text-muted">{{ timestampToDateFormat(coaching.mdfcnDt, 'yyyy.MM.dd hh:mm') }}</span></p>
<!--                  <div class="kb-form-meta"><span class="text">2022.06.11 09:45:18</span></div>-->
                </article>

                <!-- 코칭 등록 완료  -->
                <article v-else-if="coaching.crseWorkGoalsSn > 0" class="popup-section section-divider section-coaching">
                  <div class="segment-box">
                    <strong class="title">코칭</strong>
                    <p class="text">{{ coaching.goalsCn }}</p>
                  </div>
                  <p class="segment-meta">{{ coaching.mdfrNm }}({{ coaching.mdfrId }})  <span class="text-muted">{{ timestampToDateFormat(coaching.mdfcnDt, 'yyyy.MM.dd hh:mm') }}</span></p>
                </article>


              </template>
            </div>

          </div>

          <div v-if="isReady && session.deptCd === target.deptCd" class="popup-buttons">
            <button class="kb-btn kb-btn-outline kb-btn-lg" @click="closeModal"><span class="text">취소</span></button>
            <button class="kb-btn kb-btn-primary kb-btn-lg" @click="clickSaveCoaching"><span class="text">{{ coaching.crseWorkGoalsSn === 0 ? '저장' : '수정' }}</span></button>
          </div>

        </div>
      </div>
      <!-- popup > popup-close -->
      <div class="popup-close">
        <button class="kb-btn-popup-close" @click="closeModal"><i class="icon-popup-close">팝업닫기</i></button>
      </div>
    </div>
    <!-- //popup -->
  </div>

</template>
<script>

import ProfileImg from '@/components/common/ProfileImg';
import {viceTalentTargetCoachingSetup} from '@/assets/js/modules/mypage/vice/vice-setup';

export default {
  name: "ViceTalentCoachingModal",
  components: {ProfileImg},
  props: {
    toggle: Boolean,
    target: Object
  },
  emits: ['update:toggle', 'completed'],
  setup: viceTalentTargetCoachingSetup
}
</script>
