<template>
  <article class="content-section section-divider section-04">
    <header class="section-header">
      <h3 class="title">
        자기주도 학습지수 취득분포
        <button class="tool-tip-button" @click="tooltip = !tooltip"><img src="@/assets/lxp/images/common/ic_tooltip.svg" alt=""></button>
      </h3>
      <div class="tool-tip" :class="{'is-active': tooltip}">
        <div class="tool-tip-desc is-left" style="left:180px">
          자기주도 학습지수의 부서/전행 평균 비교를 통해<br/>속부점의 자기주도학습 노력도 파악이 가능합니다.

        </div>
      </div>
    </header>

    <div v-if="totalReadies.hrd && totalReadies.group " class="chart-wrap">
      <!-- chart -->
      <div class="chart chart-pie">
          <ToastNestedPieChart
              :categories="['전체평균', '부서평균']"
              :series="grapeStatus"
              :options="chartOptions"
          />
      </div>
      <!-- chart-details -->
      <div class="chart-details">
        <div class="chart-rank">
          <div class="kb-table kb-table-striped">
            <table>
              <colgroup>
                <col style="width:auto;">
                <col style="width:auto;">
              </colgroup>
              <tbody>
              <tr>
                <td><strong class="td-text">부서평균</strong></td>
                <td class="text-end"><strong class="td-text text-muted">{{ totalScore > 0 ? totalScore.toFixed(1) : 0 }}</strong></td>
              </tr>
<!--              <tr>-->
<!--                <td><strong class="td-text">지역그룹평균</strong></td>-->
<!--                <td class="text-end"><strong class="td-text text-muted">300</strong></td>-->
<!--              </tr>-->
              <tr>
                <td><strong class="td-text">전행평균</strong></td>
                <td class="text-end"><strong class="td-text text-muted">{{ totalTotalScore > 0 ? totalTotalScore.toFixed(1) : 0 }}</strong></td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="kb-table kb-table-bordered-v2 kb-table-bordered-v2-min">
      <table>
        <colgroup>
          <col style="width:102px;" />
          <col />
          <col />
          <col />
          <col />
          <col />
          <col />
          <col />
          <col />
          <col />
<!--          <col />-->
<!--          <col />-->
<!--          <col style="width:52px;" />-->
        </colgroup>
        <thead>
        <tr>
          <th class="px-0" rowspan="3"><span class="th-title">구분</span></th>
          <th rowspan="2" colspan="2"><span class="th-title">HRD 클라우드 <br/>마일리지<br>(1일 최대 2마일리지)</span></th>
          <th colspan="7"><span class="th-title">SSL 프로그램 학습활동</span></th>
<!--          <th rowspan="3"><span class="th-title">러닝라이브 <br/>연수 마일리지</span></th>-->
          <th class="px-2" rowspan="3"><span class="th-title">합계</span></th>
        </tr>
        <tr>
          <th colspan="5" class="cell-border"><span class="th-title">학습노트 작성 <br/>(건당 5점)</span></th>
          <th rowspan="2"><span class="th-title">그룹러닝 <br/>학습모임<br>(회당 5점)</span></th>
<!--          <th class="px-2" rowspan="2"><span class="th-title">지식e <br/>진단평가<br>(60점 이상<br>1회 10점)</span></th>-->
          <th class="px-2" rowspan="2"><span class="th-title">SSL 소계</span></th>
        </tr>
        <tr>
          <th class="cell-border"><span class="th-title">동영상</span></th>
          <th><span class="th-title">전산실습</span></th>
          <th><span class="th-title">가계여신</span></th>
          <th><span class="th-title">기업여신/<br>외환</span></th>
          <th><span class="th-title">자산관리/<br>퇴직연금</span></th>
          <th><span class="th-title">ICT</span></th>
          <th><span class="th-title">그룹러닝</span></th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td class="px-0" ><span class="td-text">연간누적평균</span></td>
          <td><span class="td-text">{{ onMlgScore > 0 ? onMlgScore.toFixed(1) : onMlgScore }}</span></td>
          <td><span class="td-text">{{ offMlgScore> 0 ? offMlgScore.toFixed(1) : offMlgScore }}</span></td>

          <template v-for="(value, key, index) in targetSSLCodes" :key="index">
            <td v-if="key.includes('self') || key ==='group'">
              <span class="td-text" v-html="getScore(value, key)"></span>
            </td>
          </template>
          <td><span class="td-text" v-html="groupScore > 0 ? `${groupScore.toFixed(1)}회<br>(${(groupScore * 5).toFixed(1)}점)` : '0회<br>(0점)'"></span></td>
<!--          <td><span class="td-text">{{  knowledgeScore > 0 ? knowledgeScore.toFixed(1) : knowledgeScore }}점</span></td>-->
          <td><span class="td-text">{{ sslTotalScore > 0 ? sslTotalScore.toFixed(1) : sslTotalScore }}점</span></td>
<!--          <td><span class="td-text">-</span></td>-->
          <td><span class="td-text">{{ totalScore > 0 ? totalScore.toFixed(1) : totalScore }}</span></td>
        </tr>
        </tbody>
      </table>
    </div>
  </article>

</template>
<script>

import ToastNestedPieChart from '@/components/chart/ToastNestedPieChart';
import {viceSSLSetup} from '@/assets/js/modules/mypage/vice/vice-setup';

export default {
  name: "ViceTalentSSL",
  components: {ToastNestedPieChart},
  props: {
    write: Object,
    grape: Object,
    etcGrape: Object,
    abilities: Array,
    hrdMlgs: Array,
    knowledges: Array,
    groups: Array,
    totalReadies: Object,
  },
  setup: viceSSLSetup
}
</script>
