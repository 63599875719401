<template>
<!--  <div class="main-header" style="margin-bottom: 24px">-->
  <div class="main-header">
    <!-- main-header > header-top -->
    <div class="header-top main-component">
      <div class="header-column">
        <nav class="kb-breadcrumb">
          <ol class="kb-breadcrumb-list">
            <li class="kb-breadcrumb-item"><a href="javascript:" class="kb-breadcrumb-link"><span class="kb-breadcrumb-text">홈</span></a></li>
            <li class="kb-breadcrumb-item"><a href="javascript:" class="kb-breadcrumb-link"><span class="kb-breadcrumb-text">MY</span></a></li>
            <li class="kb-breadcrumb-item is-active" aria-current="page"><span class="kb-breadcrumb-text">Talent Coaching Board</span></li>
          </ol>
        </nav>
      </div>
    </div>

    <div class="header-title">
      <h2 class="title">Talent Coaching Board</h2>
    </div>

    <!-- main-header > header-title -->
    <div class="header-top main-component mb-4">
      <div class="header-column">&nbsp;</div>
      <div class="header-column">
<!--        <button type="button" class="kb-btn kb-btn-secondary kb-btn-sm rounded-lg" @click="goToAdmin">
          <i class="icon-data"></i>
          <span class="text">개인별연수현황</span>
        </button>-->
      </div>
    </div>



    <div class="header-depth">
      <nav class="dropdown-depth main-component">
        <template v-if="dept.upDeptNms">
          <template v-for="(upDeptNm, index) in dept.upDeptNms" :key="index">
            <strong v-if="dept.deptNm" class="depth-title custom-depth-title">{{upDeptNm}}</strong>
          </template>
        </template>

        <strong v-if="dept.deptNm" class="depth-title custom-depth-title text-primary">{{dept.deptNm}}</strong>

        <template v-if="dept && dept.models">
          <template v-for="(m, index) in dept.models" :key="index">
            <SortButtonSelect2
                v-model="m.deptCd"
                v-model:toggle="m.toggle"
                title="전체"
                :options="getDeptOptions(m, index)"
                sequence-key="deptCd"
                name-key="deptNm"
                :is-all="true"
                :is-num="true"
                :width="260"
                emptyText="부서없음"
                button-class="snb-link"
                @update:toggle="closeDeptToggle(index)"
                @selected="selectedDept(index)"
            />
          </template>
        </template>

      </nav>
    </div>

    <!-- main-header > header-tab -->
    <div class="header-tab">
      <nav class="kb-nav-round-tabs">
        <ul class="kb-nav-list">
          <li v-for="(tab, index) in links" class="kb-nav-item" :key="index">
            <a href="javascript:" class="kb-nav-link" :class="{'is-active' : tab.key === link}" @click="changeLink(tab.key)"><span class="kb-nav-text">{{ tab.name }}</span></a>
          </li>
        </ul>
      </nav>
    </div>
  </div>

</template>
<style>
.custom-depth-title{
  min-width: 0 !important;
  margin-right: 32px !important;
}

</style>

<script>

import SortButtonSelect2 from '@/components/common/SortButtonSelect2';
import {viceRouteSetup} from '@/assets/js/modules/mypage/vice/vice-setup';

export default {
  name: "ViceTalentRoute",
  components: {SortButtonSelect2},
  props: {
    link: String,
    dept: Object,
    depts: Array,

  },
  emits: ['update:link', 'closeToggles', 'selectedDept'],
  setup: viceRouteSetup
}
</script>
