<template>
  <!-- begin::kb-main -->
  <main class="kb-main" id="kb-talentboard-admin" @click="closeDeptToggles(-1)">
    <!-- main-header -->
    <ViceTalentRoute
        v-model:link="link"
        :dept="dept"
        :depts="depts"
        @closeToggles="closeDeptToggles"
        @selectedDept="selectedDept"
    />
    <template v-if="readies.ability && readies.goal && readies.all">
      <ViceTalentStatus
          v-if="link === 'status'"
          :abilities="abilities"
          :goals="goals"
          :selfs="selfs"
          :all-abilities="allAbilities"
          :total-readies="totalReadies"
          :write="write"
          :grape="grape"
          :etc-grape="etcGrape"
          :readies="readies"
          :hrd-mlgs="hrdMlgs"
          :knowledges="knowledges"
          :groups="groups"
      />
      <ViceTalentCoaching
          v-else-if="link === 'coaching'"
          :abilities="abilities"
          :goals="goals"
          @completed="completedCoaching"
      />

    </template>
  </main>
  <!-- end::kb-main -->
</template>

<script>
import ViceTalentRoute from '@/components/mypage/vice/ViceTalentRoute';
import ViceTalentStatus from '@/components/mypage/vice/ViceTalentStatus';
import ViceTalentCoaching from '@/components/mypage/vice/ViceTalentCoaching';
import {viceMainSetup} from '@/assets/js/modules/mypage/vice/vice-setup';

export default {
  name: 'MyViceTalentBoard',
  components: {ViceTalentCoaching, ViceTalentStatus, ViceTalentRoute},
  setup: viceMainSetup
};
</script>
