<template>
  <div class="popup-container is-active">
    <!-- popup -->
    <div class="popup" id="popup-talentboard-o022">
      <!-- popup > popup-inner -->
      <div class="popup-inner">
        <div>
          <!-- popup-inner > popup-header -->
          <header class="popup-header">
            <h3 class="title">개인별 역량개발 상세</h3>
          </header>
          <!-- popup-inner > popup-content -->
          <div class="popup-content">
            <!-- table-list-container -->
            <div class="kb-table-list-container">
              <!-- list-top -->
              <div class="list-top">
                <div class="top-column">
<!--                  &lt;!&ndash; list-top > list-sort &ndash;&gt;-->
<!--                  <div class="list-sort">-->
<!--                    <div class="sort">-->
<!--                      &lt;!&ndash; kb-form-dropdown &ndash;&gt;-->
<!--                      &lt;!&ndash; //kb-form-dropdown &ndash;&gt;-->
<!--                    </div>-->
<!--                    <div class="sort">-->
<!--                      &lt;!&ndash; kb-form-dropdown &ndash;&gt;-->
<!--                      &lt;!&ndash; //kb-form-dropdown &ndash;&gt;-->
<!--                    </div>-->
<!--                  </div>-->
                </div>

                <div class="top-column">
                  <strong class="total">총 {{ targetAbilities.length }}명</strong>
                  <a href="javascript:" class="kb-btn kb-btn-secondary kb-btn-sm rounded-lg" @click="downloadExcel"><i class="icon-download"></i><span class="text">엑셀다운로드</span></a>
                </div>
              </div>
              <!-- //list-top -->
              <!-- list-top -->
              <nav class="list-tabs">
                <ul class="tab-list">
                  <li v-for="(tab, index) in view.tabs" class="tab-item" :class="{'is-active': view.current === tab.key}" :key="index">
                    <a href="javascript:" class="tab" @click="view.current = tab.key">{{ tab.name }}</a>
                  </li>
                </ul>
              </nav>
              <!-- //list-top -->
              <!-- table -->

                <ViceTalentAbilityProfileTable
                    v-if="view.current === 'profile'"
                    :render-abilities="pagingAbilities"
                    :paging="paging"
                />
                <ViceTalentAbilityAceTable
                    v-else-if="view.current === 'ace'"
                    :target-abilities="pagingAbilities"
                    :paging="paging"
                />
                <ViceTalentAbilitySelfTable
                    v-else-if="view.current === 'self'"
                    ref="selfDom"
                    :target-abilities="pagingAbilities"
                    :selfs="selfs"
                    :hrd-mlgs="hrdMlgs"
                    :knowledges="knowledges"
                    :groups="groups"
                />
            </div>
            <div style="margin-top: 64px">
              <CommonPaginationFront :paging="{
                  pageNo: paging.pageNo,
                  pageSize: paging.pageSize,
                  totalCount: (targetAbilities.length)
                }" :page-func="pagingFunc"
              />
            </div>
          </div>
        </div>
      </div>
      <!-- popup > popup-close -->
      <div class="popup-close">
        <button class="kb-btn-popup-close" @click="closeModal"><i class="icon-popup-close">팝업닫기</i></button>
      </div>
    </div>
    <!-- //popup -->
  </div>
</template>
<script>

import ViceTalentAbilityProfileTable from '@/components/mypage/vice/status/table/ViceTalentAbilityProfileTable';
import ViceTalentAbilityAceTable from '@/components/mypage/vice/status/table/ViceTalentAbilityAceTable';
import ViceTalentAbilitySelfTable from '@/components/mypage/vice/status/table/ViceTalentAbilitySelfTable';
import CommonPaginationFront from '@/components/CommonPaginationFront';
import {viceTargetAbilitySetup} from '@/assets/js/modules/mypage/vice/vice-setup';

export default {
  name: "ViceTalentAbilityModal",
  components: {
    CommonPaginationFront,
    ViceTalentAbilitySelfTable, ViceTalentAbilityAceTable, ViceTalentAbilityProfileTable},
  props: {
    toggle: Boolean,
    targetAbilities: Array,
    goals: Array,
    selfs: Array,
    hrdMlgs: Array,
    knowledges: Array,
    groups: Array,
  },
  emits: ['update:toggle'],
  setup: viceTargetAbilitySetup
}
</script>
