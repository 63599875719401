<template>
  <article class="content-section section-divider">
    <header class="section-header">
      <h3 class="title">
        역량개발 학습지수 현황
        <button class="tool-tip-button" @click="tooltip = !tooltip"><img src="@/assets/lxp/images/common/ic_tooltip.svg" alt=""></button>
      </h3>
      <div class="tool-tip" :class="{'is-active': tooltip}">
        <div class="tool-tip-desc is-left" style="left:170px">
          소속부점 직원들의 학습분야별/수준별 ‘인원’을 표시합니다.‘인원’을 클릭하면 개인별 역량개발 상세현황을 확인할 수 있습니다.<br>
          상세현황은 ❶프로필, ❷역량개발 학습지수 ❸자기주도 학습지수로 구분하여 확인 가능합니다.
        </div>
      </div>
      <div class="header-side">
        <div class="chart-legends">
          <div class="legend"><span class="marker marker-red"></span><span class="text">미흡</span></div>
          <div class="legend"><span class="marker marker-silver"></span><span class="text">보통</span></div>
          <div class="legend"><span class="marker marker-yellow"></span><span class="text">우수</span></div>
        </div>
      </div>
    </header>
    <div class="kb-table kb-table-bordered">
      <table>
        <colgroup>
          <col>
        </colgroup>
        <thead>
        <tr>
          <th><span class="th-title">&nbsp;</span></th>
          <th><span class="th-title">기업금융</span></th>
          <th><span class="th-title">자산관리</span></th>
          <th><span class="th-title">DT기획</span></th>
          <th><span class="th-title">DT개발</span></th>
          <th><span class="th-title">IB</span></th>
          <th><span class="th-title">자본시장</span></th>
          <th><span class="th-title">리스크</span></th>
          <th><span class="th-title">글로벌</span></th>
        </tr>
        </thead>
        <tbody>

        <tr>
          <td><span class="th-text">비교</span></td>
          <td v-for="(grade, index) in abilityGrades" :key="index">
            <div class="td-cell"><div class="marker" :class="grade"></div></div>
          </td>
        </tr>

        <tr v-for="(level, index) in tableAbilities" :key="index">
          <th scope="row"><span class="td-text">{{ level.name }}</span></th>
          <td v-for="(item, idx) in level.items" :key="`${index}-${idx}`">
            <span class="td-text kb-mouse-cursor kb-hover-underline" @click="showDetail(item, index)">{{ item.count }}</span>
          </td>
        </tr>

<!--        <tr>-->
<!--          <th scope="row"><span class="td-text">합계</span></th>-->
<!--          <td><span class="td-text kb-mouse-cursor kb-hover-underline" @click="showDetail(tableAbilities[0].items[0], 5)">{{ abilities.length }}</span></td>-->
<!--          <td><span class="td-text">{{ abilities.length }}</span></td>-->
<!--          <td><span class="td-text">{{ abilities.length }}</span></td>-->
<!--          <td><span class="td-text">{{ abilities.length }}</span></td>-->
<!--          <td><span class="td-text">{{ abilities.length }}</span></td>-->
<!--          <td><span class="td-text">{{ abilities.length }}</span></td>-->
<!--          <td><span class="td-text">{{ abilities.length }}</span></td>-->
<!--          <td><span class="td-text">{{ abilities.length }}</span></td>-->
<!--        </tr>-->

        </tbody>
      </table>
    </div>
  </article>

  <!-- content-section:KB ACE Academy 분포차트 -->
  <article class="content-section section-divider">
    <header class="section-header">
      <h3 class="title">역량개발 학습지수 분포차트</h3>
      <div class="header-side">
        <div class="chart-legends">
          <div class="legend"><span class="marker marker-vso"></span><span class="text">무등급</span></div>
          <div class="legend"><span class="marker marker-yellow"></span><span class="text">초급(Beginner)</span></div>
          <div class="legend"><span class="marker marker-vsr"></span><span class="text">중급(Intermediate)</span></div>
          <div class="legend"><span class="marker marker-vsb"></span><span class="text">고급(Advanced)</span></div>
          <div class="legend"><span class="marker marker-bb"></span><span class="text">전문가(Expert)</span></div>
        </div>
      </div>
    </header>
    <div class="chart-column">
      <div class="chart-bar-series">

        <div v-for="(ability, index) in chartAbilities" class="chart-series" :key="index">
          <div class="series-path">
            <div v-for="(level, idx) in ability.items" class="value" :class="[level.color]" :style="{flex: `0 0 ${level.height}px`}" :key="`${index}-${idx}`"></div>
          </div>
        </div>

      </div>
      <div class="chart-yaxis">
<!--        <div class="grid"><span class="grid-text">&nbsp;</span></div>-->
        <div v-for="(item, index) in chartYAxiosItems" class="grid" :key="index">
          <span class="grid-text">{{ item }}</span>
        </div>
      </div>
      <div class="chart-xaxis">
        <span class="chart-xaxis-text">기업금융</span>
        <span class="chart-xaxis-text">자산관리</span>
        <span class="chart-xaxis-text">DT 기획</span>
        <span class="chart-xaxis-text">DT 개발</span>
        <span class="chart-xaxis-text">리스크</span>
        <span class="chart-xaxis-text">자본시장</span>
        <span class="chart-xaxis-text">IB</span>
        <span class="chart-xaxis-text">글로벌</span>
      </div>
    </div>
  </article>

  <ViceTalentAbilityModal
      v-if="detail.toggle"
      v-model:toggle="detail.toggle"
      :target-abilities="detail.targetAbilities"
      :goals="goals"
      :selfs="selfs"
      :hrd-mlgs="hrdMlgs"
      :knowledges="knowledges"
      :groups="groups"
  />
</template>
<script>

import ViceTalentAbilityModal from '@/components/mypage/vice/status/ViceTalentAbilityModal';
import {viceAbilitySetup} from '@/assets/js/modules/mypage/vice/vice-setup';

export default {
  name: "ViceTalentAbility",
  components: {ViceTalentAbilityModal},
  props: {
    abilities: Array,
    goals: Array,
    selfs: Array,
    allAbilities: Array,
    hrdMlgs: Array,
    knowledges: Array,
    groups: Array,
  },
  setup: viceAbilitySetup
}
</script>
