<template>
  <div class="kb-table kb-table-bordered-v2">
  <table>
    <colgroup>
      <col style="width:104px;">
      <col style="width:120px;">
      <col>
      <col>
      <col>
      <col>
      <col>
      <col>
      <col>
    </colgroup>
    <thead>
    <tr>
      <th><span class="th-title">No</span></th>
      <th><span class="th-title">구분</span></th>
      <th><span class="th-title">직번</span></th>
      <th><span class="th-title">소속</span></th>
      <th><span class="th-title">직급</span></th>
      <th><span class="th-title">최근승격년</span></th>
      <th><span class="th-title">희망 학습분야</span></th>
      <th><span class="th-title">우수 학습분야</span></th>
      <th><span class="th-title">비고</span></th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="(item, index) in renderAbilities" :key="index">
      <td><span class="td-text">{{ (index + 1) + (paging.pageNo - 1) * paging.pageSize }}</span></td>
      <td><span class="td-text">{{ item.lrnerNm }}</span></td>
      <td><span class="td-text">{{ item.lrnerId }}</span></td>
      <td><span class="td-text">{{ item.deptNm }}</span></td>
      <td><span class="td-text">{{ item.jbgdCd }}</span></td>
      <td><span class="td-text">{{ item.curtJbgdPromoYmd && item.curtJbgdPromoYmd.length > 3 ? item.curtJbgdPromoYmd.substr(0, 4) : '-'}}</span></td>

      <td><span class="td-text">{{ item.hopeAbility.crseWorkDtlNm ? item.hopeAbility.crseWorkDtlNm : '-' }}</span></td>
      <td><span class="td-text">{{ item.bestAbility.crseWorkDtlNm ? item.bestAbility.crseWorkDtlNm : '-' }}</span></td>
      <td><span class="td-text">{{ item.etc }}</span></td>
    </tr>
    </tbody>
  </table>
  </div>
</template>
<script>

export default {
  name: "ViceTalentAbilityProfileTable",
  components: {},
  props: {
    renderAbilities: Array,
    paging: Object
  },
}
</script>
