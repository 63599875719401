<template>
  <div class="main-content min-component">
    <div class="coaching-container">
      <!-- list-top -->
      <div class="list-top">
        <div class="top-column">
          <!-- list-top > list-sort -->
          <div class="list-sort">

            <div class="sort">
              <SortButtonSelect
                  v-model="filters.goal"
                  v-model:toggle="toggles.goal"
                  title="목표 등록 여부"
                  :options="[{display: '목표 등록완료', value: 'Y'}, {display: '목표 미등록', value: 'N'}]"
                  sequence-key="value"
                  name-key="display"
                  :is-all="true"
                  @update:toggle="closeToggles(toggles, 'goal')"
                  @selected="searchItems"
              />
            </div>

            <div class="sort">
              <!-- kb-form-dropdown -->
              <SortButtonSelect
                  v-model="filters.coaching"
                  v-model:toggle="toggles.coaching"
                  title="코칭 등록 여부"
                  :options="[{display: '코칭 등록완료', value: 'Y'}, {display: '코칭 미등록', value: 'N'}]"
                  sequence-key="value"
                  name-key="display"
                  :is-all="true"
                  @update:toggle="closeToggles(toggles, 'coaching')"
                  @selected="searchItems"
              />
            </div>
          </div>
        </div>

        <div class="top-column">
          <div>
            <div class="kb-form-search">
              <div class="kb-form-search-status"><span class="text">총 {{ targetItems.length }}명</span></div>
              <div class="kb-form-search-field">
                <input v-model.trim="keyword" type="text" class="kb-form-search-input" placeholder="검색" @keyup.enter="searchKeyword">
                <button class="kb-form-search-btn" @click="searchKeyword"><i class="icon-search">검색</i></button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- //list-top -->
      <!-- list-wrapper -->
      <div class="list-wrapper">
        <!-- list-group -->
        <article class="list-group group-profile">
          <header class="group-header">
            <h4 class="title">Profile</h4>
          </header>
          <div class="kb-table kb-table-bordered">
            <table>
              <colgroup>
                <col style="width:100px">
                <col style="width:160px">
                <col>
                <col>
                <col>
                <col>
                <col style="width:87px">
              </colgroup>
              <thead>
              <tr>
                <th><span class="th-title">구분</span></th>
                <th><span class="th-title">소속</span></th>
                <th><span class="th-title">직급</span></th>
                <th><span class="th-title">최근승격년</span></th>
                <th><span class="th-title">희망 학습분야</span></th>
                <th><span class="th-title">우수 학습분야</span></th>
                <th><span class="th-title">목표</span></th>
              </tr>
              </thead>
              <tbody>
              <tr v-if="renderItems.length === 0">
                <td colspan="7" class="text-center"><span class="td-text">-</span></td>
              </tr>

              <tr v-for="(item, index) in renderItems" :key="index">
                <td><strong class="td-text text-muted">{{ item.lrnerNm }}</strong></td>
                <td><span class="td-text">{{ item.deptNm }}</span></td>
                <td><span class="td-text">{{ item.jbgdCd && item.jbgdCd.length >= 2 ? item.jbgdCd.substr(0, 2) : '-' }}</span></td>
                <td><span class="td-text">{{ item.curtJbgdPromoYmd && item.curtJbgdPromoYmd.length >= 4 ? item.curtJbgdPromoYmd.substr(0, 4) : '-' }}</span></td>
                <td>
                  <span class="td-text">
                  {{item.hopeAbility.crseWorkDtlSn > 0 ? item.hopeAbility.crseWorkDtlNm : '-'}}
                  </span>
                </td>
                <td><span class="td-text">{{item.bestAbility.crseWorkDtlNm ? item.bestAbility.crseWorkDtlNm : '-'}}</span></td>
                <td><strong class="td-text" :class="{'text-red' : item.hopeAbility.crseWorkDtlSn === 0}">{{ item.hopeAbility.crseWorkDtlSn > 0 ? '등록' : '미등록' }}</strong></td>
              </tr>
              </tbody>
            </table>
          </div>
        </article>
        <!-- list-group -->
        <article class="list-group group-coaching">
          <header class="group-header"><h4 class="title">Coaching</h4></header>
          <div class="kb-table kb-table-bordered">
            <table>
              <thead>
              <tr>
                <th><span class="th-title">등록</span></th>
                <th class="text-center"><span class="th-title">관리</span></th>
              </tr>
              </thead>
              <tbody>
                <tr v-if="renderItems.length === 0">
                  <td colspan="2" class="text-center"><span class="td-text">-</span></td>
                </tr>
                <tr v-for="(item, index) in renderItems" :key="index">
                  <td>
                    <strong v-if="item.coaching.crseWorkGoalsSn > 0" class="td-text">등록</strong>
                    <strong v-else class="td-text text-red">미등록</strong>
                  </td>
                  <td v-if="item.hopeAbility.crseWorkDtlSn > 0" class="text-center pt-1 pb-1">
                    <button class="kb-btn kb-btn-outline kb-btn-sm" @click="manageGoal(item)"><span class="text">관리</span></button>
                  </td>
                  <td v-else class="text-center"><span class="td-text">-</span></td>
                </tr>
              </tbody>
            </table>
          </div>
        </article>
      </div>


      <div style="margin-top: 32px">
        <CommonPaginationFront :paging="{
                  pageNo: paging.pageNo,
                  pageSize: paging.pageSize,
                  totalCount: (targetItems.length)
                }" :page-func="pagingFunc"
        />
      </div>
    </div>
<!--    <div v-if="hasMore" class="page-buttons" @click="moreItems">-->
<!--      <button class="kb-btn kb-btn-secondary"><span class="text">더보기</span></button>-->
<!--    </div>-->

    <ViceTalentCoachingModal
        v-if="coachingManagement.toggle"
        v-model:toggle="coachingManagement.toggle"
        :target="coachingManagement.target"
        @completed="completedCoaching"
    />


  </div>
</template>
<script>

import SortButtonSelect from '@/components/common/SortButtonSelect';
import ViceTalentCoachingModal from '@/components/mypage/vice/coaching/ViceTalentCoachingModal';
import CommonPaginationFront from '@/components/CommonPaginationFront';
import {viceTalentCoachingSetup} from '@/assets/js/modules/mypage/vice/vice-setup';

export default {
  name: "ViceTalentCoaching",
  components: {CommonPaginationFront, ViceTalentCoachingModal, SortButtonSelect},
  props: {
    abilities: Array,
    goals: Array,
  },
  emits: ['completed'],
  setup: viceTalentCoachingSetup
}
</script>
