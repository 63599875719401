<template>
  <div class="main-content min-component">
    <!-- content-section:역량개발 우수 역할 -->
    <ViceTalentBest
        v-if="readies.ability && readies.goal"
        :abilities="abilities"
        :goals="goals"
    />
    <!-- content-section:역량개발 학습지수 현황 -->
    <ViceTalentAbility
        v-if="readies.ability"
        :abilities="abilities"
        :goals="goals"
        :all-abilities="allAbilities"
        :selfs="selfs"
        :hrd-mlgs="hrdMlgs"
        :knowledges="knowledges"
        :groups="groups"
    />
    <!-- content-section:자기주도학습 지수 취득 분포 -->
    <ViceTalentSSL
        v-if="readies.self && readies.hrd && readies.group"
        :total-readies="totalReadies"
        :write="write"
        :grape="grape"
        :etc-grape="etcGrape"
        :hrd-mlgs="hrdMlgs"
        :knowledges="knowledges"
        :groups="groups"
        :abilities="abilities"
    />

  </div>
</template>
<script>

import ViceTalentBest from '@/components/mypage/vice/status/ViceTalentBest';
import ViceTalentAbility from '@/components/mypage/vice/status/ViceTalentAbility';
import ViceTalentSSL from '@/components/mypage/vice/status/ViceTalentSSL';
export default {
  name: "ViceTalentStatus",
  components: {ViceTalentSSL, ViceTalentAbility, ViceTalentBest},
  props: {
    abilities: Array,
    goals: Array,
    selfs: Array,
    allAbilities: Array,
    write: Object,
    grape: Object,
    etcGrape: Object,
    readies: Object,
    totalReadies: Object,
    hrdMlgs: Array,
    knowledges: Array,
    groups: Array
  },
}
</script>
