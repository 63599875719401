<template>
  <div class="kb-table kb-table-bordered-v2" style="overflow-x: auto; max-width: 1200px;">
    <table>
      <colgroup>
        <col style="width:104px;">
        <col style="width:160px;">
        <col style="width:160px;">
        <col style="width:160px;">
        <col style="width:160px;">
        <col style="width:160px;">
        <col style="width:160px;">
        <col style="width:160px;">
        <col style="width:160px;">
        <col style="width:160px;">
      </colgroup>
      <thead>
      <tr>
        <th><span class="th-title">No</span></th>
        <th><span class="th-title">구분</span></th>
        <th><span class="th-title">기업금융</span></th>
        <th><span class="th-title">자산관리</span></th>
        <th><span class="th-title">DT기획</span></th>
        <th><span class="th-title">DT개발</span></th>
        <th><span class="th-title">IB</span></th>
        <th><span class="th-title">자본시장</span></th>
        <th><span class="th-title">리스크</span></th>
        <th><span class="th-title">글로벌</span></th>
      </tr>
      </thead>
      <tbody>

      <tr v-for="(item, index) in targetAbilities" :key="index">
        <td><span class="td-text">{{ (index + 1) + (paging.pageNo - 1) * paging.pageSize }}</span></td>
        <td><span class="td-text">{{ item.lrnerNm }}</span></td>
        <td v-for="(score, idx) in getScoreItems(item)" :key="`${index}-${idx}`">
          <span class="td-text">{{ score.level }}</span>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>
<script>

import {viceAbilityAceTableSetup} from '@/assets/js/modules/mypage/vice/vice-setup';

export default {
  name: "ViceTalentAbilityAceTable",
  components: {},
  props: {
    targetAbilities: Array,
    paging: Object
  },
  setup: viceAbilityAceTableSetup
}
</script>
